import { ExtLink } from "@app/site";
import {
  AppLogoIcon,
  BButton,
  BMessage,
  Box,
  EmailIcon,
  Heading,
  NotFoundIcon,
  RepeatIcon,
  Spacer,
  VBox,
} from "@app/ui";
import * as React from "react";

// markup
const NotFoundPage = () => {
  return (
    <BMessage
      message={
        <Heading as="span" size="lg" fontWeight="400">
          Sorry, Page is not found. &#128546;
        </Heading>
      }
      icon={
        <>
          {/* <AppLogoIcon boxSize={40} mb={10} /> */}
          <NotFoundIcon boxSize={32} m={10} />
        </>
      }
      action={
        <VBox height={"100%"}>
          <Spacer h={32} />
          {/* <Text as="span" mb={2}>
            Check the URL and try again
          </Text> */}

          <Box as="form" action={"?t=" + new Date().getTime()} method="GET">
            <BButton
              type="submit"
              onClick={() => window.location.reload()}
              text={"Refresh the page"}
              shortText={"Refresh the page"}
              icon={<RepeatIcon boxSize={6} ml={2} />}
            />
          </Box>
          <Box mt={6}>
            <ExtLink href={"mailto:support@decked.link"} py={2.5} px={2}>
              Let us know about the issue
              <EmailIcon boxSize={6} ml={2} />
            </ExtLink>
          </Box>
          <Box mt={8} fontSize="sm">
            Powered by
            <ExtLink href={process.env.GATSBY_SITE_BASE_URL} blank>
              {/* <AppIcon mr={0.5} boxsize={4} /> */}
              {/* DeckedLink */}
              <AppLogoIcon boxSize={24} py={1.5} />
            </ExtLink>
          </Box>
        </VBox>
      }
    />
  );
};

export default NotFoundPage;
